(function () {
    // keep track of the previous element that opened the modal
    // allowing users navigating via keyboard to return to where they were
    var $previousElement = null;

    //note if you want the fade in effect add the 'fade' class in for class name
    window.generateModalTemplate = function (html, className, title) {

        // set previous element to allow user to return to their place on the page
        $previousElement = $(':focus') || document.body;

        var $dynamicModal = $('<div id="dynamic-modal" aria-modal="true" role="dialog" aria-label="Dialog opened" class="modal fade in" tabindex="1">\
            <div class="modal-dialog">\
                <div class="modal-btn-close btn-close" data-dismiss="modal" role="button" aria-label="Close dialog"></div>\
                <div class="modal-content"></div>\
            </div>\
         </div>'),
            $modalContent = $dynamicModal.find('.modal-content'),
            $modalTitle = '',
            $modalBody;

        if (title) {
            $modalTitle = $('<div class="modal-header">\
                        <h3 class="modal-title"></h3>\
                    </div>');
            $modalTitle.find('.modal-title').append(title);
            $modalBody = $('<div class="modal-body"></div>');
            $modalBody.append(html);
        } else {
            $modalBody = $(html || '');
        }

        if (className) {
            $dynamicModal.addClass(className);
        }

        $modalContent.append($modalTitle, $modalBody);

        // hide existing modals (this enables modal links from inside other modals)
        $('.modal').modal('hide');

        // Run embededed scripts.
        // This is a workaround for jQuery blocking the main thread while loading scripts
        $dynamicModal.on('shown.bs.modal', function () {
            var $modalScripts = $('script[type="javascript/ajaxwrapper"]');
            if ($modalScripts.length) {
                $modalScripts.each(function () {
                    $.getScript(this.getAttribute('src'));
                });
            }
        });

        $dynamicModal.prependTo(evo.$.body).modal();

        //need to remove it on hide to keep videos from playing other classes from perpetuating etc.
        $dynamicModal.on('hidden.bs.modal', function (e) {
            $dynamicModal.remove();

            // return focus to element that triggered modal
            if ($previousElement) {
                $previousElement.focus();
            } else {
                // Handle case where previous element no longer exists, such as 
                // modal opened from a different modal
                document.body.focus();
            }
            
            $previousElement = null;
        });

        // set focus to the modal so users with screenreaders can interact with modal
        $dynamicModal.focus();

        return $dynamicModal;
    };

    // generate a modal that loads a form via ajax, as well as all subsequent posts
    window.generateAjaxFormModal = function (html, className, title) {
        var $modal = generateModalTemplate(html, className, title);

        var bindAjaxForm = function ($form) {
            $form.on('submit', function (e) {
                e.preventDefault();
                var $this = $(this);

                if (!$this.valid()) {
                    return;
                }

                var url = $this.attr('action'),
                    method = $this.attr('method') || 'POST',
                    data = $this.serialize(),
                    options = checkForCorsRequest({
                        url: url,
                        method: method,
                        data: data
                    });

                $modal.find('.modal-dialog').addClass('loading');
                $.ajax(options).then(function (response) {
                    if (response) {
                        var $modalBody = $modal.find('.modal-body');
                        if (!$modalBody.length) {
                            $modalBody = $modal.find('.modal-content');
                        }
                        $modalBody.html(response);
                        bindAjaxForm($modalBody.find('form'));
                    }
                }).always(function () {
                    $modal.find('.modal-dialog').removeClass('loading');
                });
            });

            if (!evo.iOS) {
                // HACK alert. mobile safari loses its mind trying to focus on these modal elements
                $form.find('input:visible:first').focus();
            }
            $.validator.unobtrusive.parse($form);
        };

        bindAjaxForm($modal.find('form'));

        return $modal;
    };

    // If url is full length url and is ssl,
    // set withCredentials: true for cors request
    function checkForCorsRequest(options) {
        if (options.url && options.url.length > 5 && document.location.protocol == 'http:' && options.url.substr(0, 6) == 'https:') {
            options.crossDomain = {
                crossDomain: true
            };
            options.xhrFields = {
                withCredentials: true
            };
        }
        return options;
    }

    function modalInputFix() {
        var scrollTop,
            $htmlBody = $('html, body'),
            $page = $('#evo-page');

        if (Modernizr.touch) {
            evo.$.body.on('show.bs.modal', function () {
                scrollTop = evo.$.window.scrollTop();
                $htmlBody.addClass('modal-fix');
                $page.css('top', (scrollTop * -1) + 'px');
            }).on('hide.bs.modal', function () {
                $htmlBody.removeClass('modal-fix');
                $page.css('top', 0);
                evo.$.window.scrollTop(scrollTop);
            });
        }
    };

    $(function () {
        modalInputFix();

        //load html to the modal window for data-toggle=modal-html
        evo.$.body.on('click', '[data-toggle=modal-html]', function (e) {
            e.preventDefault();
            var $this = $(this),
                contentId = $this.data('id'),
                title = $this.data('title'),
                additionalClass = $this.data('class') || '',
                $html = $('#' + contentId).children();

            var modal = generateModalTemplate($html, additionalClass, title);
            modal.on('hide.bs.modal', function () {
                $('#' + contentId).append($html);
            });
        });

        // Show a modal with the given image
        function showImageModal(src, title, additionalClass) {
            var $content = $('<img class="modal-image" id="modal-image" />').attr({ src: src, alt: title });
            $content.on('load', function () {
                $('#dynamic-modal').removeClass('modal-loading');
            });
            return generateModalTemplate($content, 'modal-image-wrapper modal-loading ' + additionalClass);
        }

        // Get content from url as modal
        function getModalPage(url) {
            var ajaxOptions = checkForCorsRequest({
                cache: false, // IE will cache the full login page :(
                headers: { 'X-Modal-Request': 'true' },
                url: url
            });
            return $.ajax(ajaxOptions);
        }

        var image_regex = /\.(bmp|gif|jpg|jpeg|png|svg|tiff|webp)$/i;

        // Load content into modal via ajax
        // Specifying data-id will just load the contents of a div on the AJAX request page otherwise the whole resulting HTML will load.
        evo.$.body.on('click', '[data-toggle=modal-load],[data-toggle=modal-ajax]', function (e) {
            e.preventDefault();
            var $this = $(this),
                additionalClass = $this.data('class') || '',
                contentId = $this.data('id'),
                modalTitle = $this.data('modal-title'),
                contentUrl = $this.attr('href') || $this.data('href');

            if (image_regex.test(contentUrl))
                return showImageModal(contentUrl, modalTitle, additionalClass);

            var $modal = generateModalTemplate('', 'modal-ajax modal-loading ' + additionalClass, modalTitle);

            return getModalPage(contentUrl).then(function (html) {
                if (contentId) {
                    var $modalHTML = $('<div>').html(html);
                    html = $modalHTML.find('#' + contentId).html();
                }
                $modal.removeClass('modal-loading').find(modalTitle ? '.modal-body' : '.modal-content').html(html);
                evo.$.body.trigger('modal-html-loaded');
            });
        });

        //Load an angular app
        evo.$.body.on('click', '[data-toggle=modal-angular]', function (e) {
            e.preventDefault();
            var $this = $(this),
                appSource = $this.attr('href') || $this.data('href'),
                contentId = $this.data('id'),
                additionalClass = $this.data('class') || '';

            $this.addClass("loading");

            evo.$.body.append('<div class="hidden">\
                        <iframe src="' + appSource + '" class="modal-angular-iframe"></iframe>\</div>');

            $('.modal-angular-iframe').load(function () {
                var $angularHtml = $('.modal-angular-iframe').contents().find('#' + contentId);

                generateModalTemplate($angularHtml, 'modal-angular ' + additionalClass);
                $this.removeClass("loading");
            });
        });

        //load an image to the modal window for data-toggle=modal-image
        evo.$.body.on('click', '[data-toggle=modal-image]', function (e) {
            e.preventDefault();
            var $this = $(this),
                imgSrc = $this.attr('href'),
                imgTitle = $this.attr('title') || '',
                additionalClass = $this.data('class') || '';

            showImageModal(imgSrc, imgTitle, additionalClass);
        });

        //bind close click event
        $('.modal-btn-close').on('click touchstart', function (e) {
            e.preventDefault();
            $('.modal').modal('hide');
        });

        //load an video to the modal window for data-toggle=modal-video
        evo.$.body.on('click', '[data-toggle=modal-video]', function (e) {
            e.preventDefault();
            var $this = $(this),
                videoSource = $this.data('source'),
                videoKey = $this.data('key'),
                additionalClass = $this.data('class') || '',
                videoHTML, embedURL;

            switch (videoSource.toLowerCase()) {
                case 'vimeo':
                    embedURL = 'https://player.vimeo.com/video/' + videoKey + '?autoplay=1&title=0&byline=0&portrait=0';
                    break;
                case 'youtube':
                    embedURL = 'https://www.youtube.com/embed/' + videoKey + '?rel=0&amp;showinfo=0&autoplay=1';
                    break;
                default:
                    alert("The video source " + videoSource + " is not valid");
            }

            videoHTML = '<div class="modal-video-wrapper">\
                        <iframe src="' + embedURL + '" class="modal-video-iframe" width="720" height="405" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>\
                    </div>';

            generateModalTemplate(videoHTML, 'modal-video ' + additionalClass);
        });

        //load a form via modal (i.e. login) for data-toggle=modal-form
        evo.$.body.on('click', '[data-toggle=modal-form]', function (e) {
            e.preventDefault();
            var $this = $(this),
                additionalClass = $this.data('class') || '',
                modalTitle = $this.data('modal-title') || $this.attr('title');

            getModalPage($this.attr('href')).then(function (response) {
                generateAjaxFormModal(response, additionalClass, modalTitle);
                //total hack because keypad makes the page go haywire on iOS.
                if (evo.iOS) {
                    setTimeout(function () {
                        scrollLocation = $(window).scrollTop();
                        $('.modal')
                            .addClass('modal-ios')
                            .height($(window).height())
                            .css({ 'margin-top': 0 + 'px' });
                    }, 0);
                }
            });
        });

        //load a given url in an iframe to isolate it from the current page
        evo.$.body.on('click', '[data-toggle=modal-frame]', function (e) {
            e.preventDefault();
            var $this = $(this),
                href = $this.attr('href'),
                additionalClass = $this.data('class') || '',
                modalTitle = $this.data('modal-title') || $this.attr('title'),
                qsIndex = href.indexOf('?'),
                hIndex = href.indexOf('#');

            if (qsIndex > -1) {
                href = href.splice(qsIndex + 1, 0, 'frame=true&');
            }
            else if (hIndex > -1) {
                href = href.splice(hIndex, 0, '?frame=true');
            }
            else {
                href += '?frame=true'
            }

            var $frame = $('<iframe />'),
                modal;
            $frame.attr('src', href);
            $frame.css('width', '100%');
            $frame.css('border', 'none');
            $frame.on('load', function () {
                var $this = $(this),
                    $body = $(this.contentDocument.body);

                modal.removeClass('modal-loading');

                $body.css('max-width', $frame.width() + 'px');
                $this.css('height', $body.outerHeight() + 35 + 'px');
                $body.css('overflow', 'hidden');
                // Prevent links from loading in the iframe, unless they are hash links
                $body.on('click', 'a', function (e) {
                    if (this.onclick)
                        return;

                    var linkHref = $(this).attr('href');
                    if (linkHref[0] == '#' || linkHref.split('#')[0].toLowerCase() == $this.attr('src').split('#')[0].toLowerCase())
                        return;

                    location.href = linkHref;
                    e.preventDefault();
                });
            });
            modal = generateModalTemplate($frame, additionalClass + ' modal-loading', modalTitle);
        });
    });
})();